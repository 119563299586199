<template>
	<div v-if="combinedSlides.length" class="relative">
		<!-- .embla -->
		<div ref="emblaRef" class="relative overflow-hidden">
			<!-- .embla__container -->
			<div class="flex">
				<!-- .embla__slide  -->
				<NuxtLink
					v-for="(image, i) in combinedSlides"
					:key="image.id"
					:to="image.link ? image.link : '#'"
					no-prefetch
					class="flex-[0_0_100%]"
				>
					<picture
						class="h-[calc[100vw/2.16]] w-screen max-w-[1450px] md:h-[calc(100vw/4.83)] min-[1450px]:w-[1450px]"
					>
						<source
							:srcset="image.mobileUrl"
							media="(max-width: 767px)"
							width="891"
							height="413"
						/>
						<source
							:srcset="image.desktopUrl"
							media="(min-width: 768px)"
							width="1994"
							height="413"
						/>
						<img
							:src="image.desktopUrl"
							:alt="
								image.desktopAlt
									? image.desktopAlt
									: `Weedys home page banner slideshow image ${i + 1}`
							"
							:loading="i ? 'lazy' : 'eager'"
						/>
					</picture>
				</NuxtLink>
			</div>
			<!-- Slider controls -->
			<button
				aria-label="Previous slide"
				class="absolute left-6 top-[43%] hidden rounded-full bg-white/30 p-2 md:flex"
				@click="scroll(false)"
			>
				<ChevronLeftIcon class="size-5 text-text" />
			</button>
			<button
				aria-label="Next slide"
				class="absolute right-6 top-[43%] hidden rounded-full bg-white/30 p-2 md:flex"
				@click="scroll(true)"
			>
				<ChevronRightIcon class="size-5 text-text" />
			</button>
		</div>
		<!-- Pagination dots -->
		<div class="-mt-2 flex w-full justify-center gap-1 md:hidden">
			<span
				v-for="n in combinedSlides.length"
				:key="n"
				class="block size-1 rounded"
				:class="{
					'bg-primary': n - 1 === activeIndex,
					'bg-gray-300': n - 1 !== activeIndex,
				}"
			></span>
		</div>
	</div>
</template>

<script setup lang="ts">
import emblaCarouselVue from 'embla-carousel-vue';
import AutoPlay from 'embla-carousel-autoplay';
import type { ImageWithImageRelations } from '~/types/app.types';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/outline';

const baseURL = useRuntimeConfig().public.baseUrl;

const { data: desktop } = useFetch<Record<'images', ImageWithImageRelations[]>>(
	'/api/images/location/index-hero-desktop',
	{
		baseURL,
		cache: 'no-cache',
		onResponseError({ response }) {
			handleFrontendError(response, false);
		},
	},
);

const { data: mobile } = useFetch<Record<'images', ImageWithImageRelations[]>>(
	'/api/images/location/index-hero-mobile',
	{
		baseURL,
		cache: 'no-cache',
		onResponseError({ response }) {
			handleFrontendError(response, false);
		},
	},
);

const combinedSlides = computed(() => {
	if (!desktop.value || !mobile.value) return [];
	return desktop.value?.images.map((image, i) => ({
		desktopUrl: image.url,
		desktopAlt: image.altText,
		mobileUrl: mobile.value?.images[i].url,
		mobileAlt: mobile.value?.images[i].altText,
		link: image.imageRelations[0].link,
		id: i,
	}));
});

const activeIndex = ref(0);

const [emblaRef, emblaApi] = emblaCarouselVue({ loop: true, inViewThreshold: 0.5 }, [
	AutoPlay({ stopOnInteraction: false, stopOnMouseEnter: true, delay: 10000 }),
]);

function scroll(forward: boolean) {
	if (!emblaRef.value || !emblaApi.value) return;
	if (forward) {
		emblaApi.value?.scrollNext();
	} else {
		emblaApi.value?.scrollPrev();
	}
}

if (desktop.value?.images.length) {
	useServerHead(
		{
			link: [{ rel: 'preload', href: desktop.value.images[0].url, as: 'image' }],
		},
		{ tagPriority: -4 },
	);
}

onMounted(() => {
	emblaApi.value?.on('slidesInView', () => {
		activeIndex.value = emblaApi.value?.slidesInView()[0] || 0;
	});
});
</script>
